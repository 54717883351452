// import Landing from "./landing";
// import About from "./about";
// import Careers from "./careers";
// import Support from "./support";
// import NotFound from "./not-found";
// import PrivacyPolicy from "./privacy-policy";
// import Maintenance from "./maintenance";
// import Employee from "./employee";
// import IdCard from "./id-card";
// import WalletAddress from "./wallet-address";
// import ResetPin from "./reset-pin";

import { lazy } from "react";

const Landing = lazy(() => import("./landing"));
const About = lazy(() => import("./about"));
const Careers = lazy(() => import("./careers"));
const Support = lazy(() => import("./support"));
const NotFound = lazy(() => import("./not-found"));
const PrivacyPolicy = lazy(() => import("./privacy-policy"));
const TermsAndConditions = lazy(() => import("./terms-and-conditions"));
const Maintenance = lazy(() => import("./maintenance"));
const Employee = lazy(() => import("./employee"));
const Onramp = lazy(() => import("./onramp"));
const IdCard = lazy(() => import("./id-card"));
const WalletAddress = lazy(() => import("./wallet-address"));
const ResetPin = lazy(() => import("./reset-pin"));
const AppAnalytics = lazy(() => import("./app-analytics"));

export {
  Landing,
  About,
  Careers,
  Support,
  PrivacyPolicy,
  TermsAndConditions,
  NotFound,
  Maintenance,
  Employee,
  Onramp,
  IdCard,
  WalletAddress,
  ResetPin,
  AppAnalytics,
};
