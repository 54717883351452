import { Routes, Route } from "react-router-dom";
import {
  Landing,
  About,
  Careers,
  Support,
  PrivacyPolicy,
  TermsAndConditions,
  NotFound,
  Maintenance,
  Employee,
  Onramp,
  IdCard,
  WalletAddress,
  ResetPin,
  AppAnalytics,
} from "./pages";

const App = ({ history }) => {
  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="about" element={<About />} />
      <Route path="careers" element={<Careers />} />
      <Route path="support" element={<Support />} />
      <Route path="privacy-policy" element={<PrivacyPolicy />} />
      <Route path="terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="maintenance" element={<Maintenance />} />
      <Route path="emp" element={<Employee />} />
      <Route path="onramp" element={<Onramp />} />
      <Route path="id-card" element={<IdCard />} />
      <Route path="@:username" element={<WalletAddress />} />
      <Route path="reset-pin/:code" element={<ResetPin />} />
      <Route path="app-analytics" element={<AppAnalytics />} />
      <Route
        path="*"
        element={process.env.REACT_APP_EXTENSION ? <Landing /> : <NotFound />}
      />
    </Routes>
  );
};

export default App;
